import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigService } from '../service/app-config.service';
import { map, switchMap, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  partnerSsoUrl = `auth/saml/login?idp=${this.appConfig.getConfig().partnerSSOEntityID}&RelayState=${window.location.origin}`;
  vendorSsoUrl = `auth/saml/login?idp=${this.appConfig.getConfig().vendorSSOEntityID}&RelayState=${window.location.origin}`;
  window = window;
  username: string;
  password: string;
  dataLoading = false;
  code: number;
  errorMessage: string;
  newUserPage = 'requestRole';

  codes = [
    { code: 201, message: 'You Have Successfully Logged Out' },
    { code: 400, message: 'Invalid credentials. Please try again.' },
    { code: 401, message: 'Your Session Has Expired' },
    { code: 403, message: 'You Do Not Have Sufficient Privileges To Access' }
  ];

  constructor(@Inject(DOCUMENT) private document: Document, public auth: AuthService, private router: Router, public appConfig: AppConfigService,
              private activatedRoute: ActivatedRoute) {
      const script = document.createElement('script');
      script.type = 'module';
      script.src = 'https://mortar-cdn.heb.com/elements/@/19.0.2/assembled/interstitial/index.js';
      this.document.head.appendChild(script);
  }

  ngOnInit() {
    this.auth.successMessage$.pipe(
      take(1),
    ).subscribe(result => {
      this.loginSuccess(result);
    });
    this.auth.errorMessage$.subscribe(error => {
      this.loginFailure(error);
    });
    // sets route return to
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.code = params['code'];
    });
    this.auth.isNotAuthenticatedLogout();
  }

  /**
   * Login handler.
   */
  login() {
    this.errorMessage = null;
    this.dataLoading = true;
    this.auth.login(this.username, this.password);
  }

  /**
   * Login handler success.
   *
   * @param user
   */
  loginSuccess(user) {
    this.dataLoading = false;
    this.username = undefined;
    this.password = undefined;
    this.auth.initializeCurrentRole().pipe(
      map(() => this.auth.isUser() ? '/' : this.newUserPage),
      switchMap(url => this.router.navigateByUrl(url)),
    ).subscribe();
  }


  /**
   * Login hanlder fail.
   *
   * @param error
   */
  loginFailure(error) {
    this.dataLoading = false;
    for (let index = 0; index < this.codes.length; index++) {
      if (this.codes[index].code === error.status) {
        this.errorMessage = this.codes[index].message;
      }
    }
    if (!this.errorMessage) {
      this.errorMessage = error.message;
    }
  }
}
