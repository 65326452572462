import {Component, OnInit} from '@angular/core';
import {GrowlService} from './2.0.0/growl/growl.service';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import { AuthService } from './2.0.0/auth/auth.service';
import { AppConfigService } from './2.0.0/service/app-config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

 public version: string = environment.VERSION;
 useVersion1UI: boolean = true;

  constructor(private growlService: GrowlService, private router: Router, private titleService: Title,
              public auth: AuthService, public appConfig: AppConfigService) {
    this.titleService.setTitle('Product Attribute Management');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navigationEnded();
      }
    });
  }

  /**
   * A callback method that is invoked immediately after the
   * default change detector has checked the directive's
   * data-bound properties for the first time,
   * and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  ngOnInit(): void {
    this.titleService.setTitle('PAM ' + this.version);
  }

  /**
   * Handle application wide logic when any navigation has successfully ended.
   */
  navigationEnded() {
    this.growlService.clearMessages(); // clear out any growl message when leaving any page
  }

}
