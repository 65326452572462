import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AttributeProductImagesConfig,
  CandidateProduct,
  CandidateProductError,
  ProductImagesCardErrors,
  ProductImagesCardModel,
  ProductImagesCardPermissions
} from 'pm-models';

@Component({
  selector: 'pm-product-images-card',
  templateUrl: './product-images-card.component.html',
  styleUrls: ['./product-images-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductImagesCardComponent implements OnInit {

  @Input()
  model: ProductImagesCardModel | CandidateProduct;
  @Output() modelChange = new EventEmitter<any>();

  @Input()
  permissions: ProductImagesCardPermissions;

  @Input()
  errorModel: ProductImagesCardErrors | CandidateProductError;

  @Input()
  showImageOmissionReasons: boolean = false;

  public readonly ecomContentStandardsUrl = '/candidates/training_guides/eCommerce-Content-Standards.pdf';

  public attributeProductImagesConfig: AttributeProductImagesConfig;

  constructor() {
  }

  ngOnInit() {
    this.attributeProductImagesConfig = {
      showOmissionReasons: this.showImageOmissionReasons
    };
  }

}
