<pm-review #pmReview *ngIf="isViewingPage" (closeEvent)="onClose()">

  <pm-title>{{getPageTitle()}}</pm-title>

  <div *ngIf="candidate" class="row justify-content-md-center pt-3 pb-3">
    <div class="col col-md-10">
      <div class="row" style="margin-left: 5px;">
        <img class="product-image" src="{{productImages.length > 0 ? productImages[0] : DEFAULT_NO_PRODUCT_IMAGE}}">
        <div>
          <div *ngIf="!candidateUtilService.isPlu(candidate)" class="upc-header">
            UPC: {{candidate.candidateProducts[0].upc}}-{{candidate.candidateProducts[0].upcCheckDigit}}
          </div>
          <div *ngIf="candidateUtilService.isPlu(candidate)" class="upc-header">
            <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
              PLU: {{candidate.candidateProducts[0].upc | upc}}-{{candidate.candidateProducts[0].upc | upcCheckDigit}}<br>
            </div>
            <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
              UPC: {{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc)}}-{{upcService.pluToG14PreDigitTwo(candidate.candidateProducts[0].upc) | upcCheckDigit}}
            </div>
          </div>
          <div class="attribute-description">
            <p>{{candidateProduct.description}}</p>
            <p>{{getProductInfoString()}}</p>
          </div>
        </div>
      </div>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pre-price'" [canEdit]="false">
              {{candidate.prePrice | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked'" [canEdit]="false">
              {{candidate.costLinked | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost linked item'" [canEdit]="false">
              {{candidate.costLink}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Suggested retail'" [canEdit]="false">
              {{candidate.suggestedXFor}} for {{costService.toCurrency(candidate.suggestedRetailPrice)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>

                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
                Penny profit: {{costService.getPennyProfit(candidate)}}<br>
                Margin: <a [ngClass]="{'grid-cell-error':costService.isMarginNegativeOrZero(candidate)}">{{costService.getMargin(candidate)}}%</a>
                <img *ngIf="costService.isMarginNegativeOrZero(candidate)" class="ui-toast-message-warn" src="/assets/images/triangle_exclamation.svg" [pTooltip]="costService.MARGIN_TOOLTIP">
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'MAP retail'" [canEdit]="false">
              {{candidate.mapRetail ? costService.toCurrency(candidate.mapRetail) : ''}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Cost set by supplier  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Cost set by supplier'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Master list cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.masterListCost)}}
              <pm-grid-cell-info>
                Inner list cost: {{costService.toCurrencyForCost(candidate.innerListCost)}}<br>
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Unit cost'" [canEdit]="false">
              {{costService.toCurrencyForCost(candidate.unitCost)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deal offered'" [canEdit]="false">
              {{candidate.dealOffered ? 'Yes' : 'No'}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case Summary -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case description'">
              {{candidateProduct.caseDescription}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Channel'"
                          [errorMessages]="[candidateError?.warehouseSwitch, candidateError?.dsdSwitch]">
              {{candidateUtilService.getChannel(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}-{{candidateProduct.caseUpcCheckDigit}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Country of origin'"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!-- Case Summary -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Case summary'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Case UPC'"
                          [errorMessages]="[candidateProductError?.caseUpc, candidateProductError?.caseUpcCheckDigit]">
              {{candidateProduct.caseUpc}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'VPC'" [errorMessage]="candidateProductError?.vendorProductCode">
              {{candidateProduct.vendorProductCode}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Country of origin'"
                          [errorMessage]="candidateProductError?.countryOfOrigin">
              {{candidateProduct.countryOfOrigin?.description}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--Retail set by buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Retail set by buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Pricing type'" [canEdit]="false">
              {{candidate.retailType}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'HEB margin'" [canEdit]="false">
              {{costService.getHebMargin(candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Retail pricing'" [canEdit]="false">
              {{costService.getRetailPricing(candidate) | emptyToValue: '&#8212;'}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'HEB penny profit'" [canEdit]="false">
              {{costService.getHebPennyProfit(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell></pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Master Pack   -->
      <pm-attribute-grid [title]="'Master pack'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Total retail units'" [canEdit]="false">
              {{candidate.masterPack}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Boxes on pallet'" [canEdit]="false">
              {{+candidate.vendorTie * +candidate.vendorTier}}
              <pm-grid-cell-info>
                Tie: {{candidate.vendorTie}}
                Tier: {{candidate.vendorTier}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight type'" [canEdit]="false">
              {{candidateUtilService.getItemWeightType(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Order factor'" [canEdit]="isPageEditable" [errorMessage]="candidateError?.cubeAdjustedFactor" (editClicked)="editCandidate(attributeType.OrderFactor)">
              {{candidate.cubeAdjustedFactor}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Code date'" [canEdit]="false">
              {{candidate.codeDate | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Max shelf life'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.maxShelfLife)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Inbound spec'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.inboundSpecDays)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>
      <!--  inner packs -->
      <pm-attribute-grid [title]="'Inner packs'">
        <pm-grid-group *ngIf="candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.innerPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getInnerDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.innerLength, candidate.innerWidth, candidate.innerHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.innerWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group *ngIf="!candidate.innerPackSelected">
          <pm-grid-column>
            <pm-grid-cell [label]="'Inner packs quantity'" [canEdit]="false">
              {{candidate.masterPack}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getMasterDimensions(candidate)}}
              <pm-grid-cell-info>
                Cubic Ft.: {{candidateUtilService.getCubicFeet(candidate.masterLength, candidate.masterWidth, candidate.masterHeight,decimalCount)}}
              </pm-grid-cell-info>
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getWeightString(candidate.masterWeight)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Display ready units'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Display ready unit'" [canEdit]="false">
              {{candidate.displayReadyUnit | yesNo}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Orientation'" [canEdit]="false">
              {{candidateUtilService.getDRUOrientation(this.candidate)}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'# Retail units'" [canEdit]="false">
              {{candidate.displayReadyUnit ? candidate.displayReadyUnitRowsDeep * candidate.displayReadyUnitRowsFacing * candidate.displayReadyUnitRowsHigh : 0}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Display ready units not needed for non sellable -->

      <!--      Product details from buyer-->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- Column 1 -->
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{candidate.merchandiseType.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity?.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 2 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Like item code'" [canEdit]="false">
              {{candidate.likeId}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Season & Year'" [canEdit]="false">
              {{candidate.season?.seasonDescription}} {{candidate.seasonYear}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Estimated store count'" [canEdit]="false">
              {{candidate.numberOfStores}}
            </pm-grid-cell>
          </pm-grid-column>
          <!-- Column 3 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Sold by weight'" [canEdit]="false">
              {{candidate.weightSwitch | yesNo}}
            </pm-grid-cell>

            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--      Product details from buyer-->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product details from buyer'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- Column 1 -->
            <pm-grid-cell [label]="'Merchandise type'" [canEdit]="false">
              {{candidate.merchandiseType.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Reaction days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.warehouseReactionDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <!-- Column 2 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'PSS department'" [canEdit]="false">
              {{candidate?.pssDepartment?.displayName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Commodity'" [canEdit]="false">
              {{candidate.commodity.commodityName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Guarantee to store days'" [canEdit]="false">
              {{candidateUtilService.getDaysString(candidate, candidate.guaranteeToStoreDays)}}
            </pm-grid-cell>
          </pm-grid-column>

          <!-- Column 3 -->
          <pm-grid-column>
            <pm-grid-cell [label]="'Sub-commodity'" [canEdit]="false">
              {{candidate.subCommodity.subCommodityName}} [{{candidate.subCommodity?.subCommodityId}}]
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Supplier & H-E-B details  -->
      <pm-attribute-grid [title]="'Supplier & H-E-B details'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Brand'" [canEdit]="false">
              {{candidate.brand.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Sub-brand'" [canEdit]="false">
              {{candidateProduct.subBrand?.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Cost owner'" [canEdit]="false">
              {{candidate.costOwner.costOwnerName}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Contact'" [canEdit]="false">
              {{candidate.contactName}}
              {{candidate.contactEmail}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Top to top'" [canEdit]="false">
              <input type="text" pInputText [(ngModel)]="candidate.costOwner.topToTopName"  readonly="readonly"/>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Supplier'" [canEdit]="false">
              {{candidate.vendor.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Warehouse supplier'" [editNoHover]="isPageEditable" (editClicked)="editWarehouseSupplier()" >
              {{candidate.lane.name}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Buyer'" [canEdit]="false">
              {{candidate.buyer.buyerName}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <!-- <pm-grid-cell [label]="'Channel'" [canEdit]="false">
              {{getChannel()}}
            </pm-grid-cell> -->
            <pm-grid-cell [label]="'Sellable'" [canEdit]="false">
              {{getSellableString()}}
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
            <pm-grid-cell>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Product line, packaging, & regulatory info'">
        <pm-grid-group>
          <pm-grid-column>
            <!-- <pm-grid-cell [label]="'Country of origin'" [canEdit]="false">
              {{candidateProduct.countryOfOrigin.description}}
            </pm-grid-cell> -->
            <pm-grid-cell [label]="'Food stamp eligible'" [canEdit]="false">
              {{candidate.foodStamp | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Taxable'" [canEdit]="false">
              {{candidate.taxable | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [canEdit]="false" [label]="'FSA/HSA eligible'">
              {{candidate.flexibleSpendingAccount | yesNo}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Deposit UPC'" [canEdit]="false" [errorMessages]="[candidateProductError?.depositScanCodeId]">
              {{candidateProduct.depositScanCodeId}}-{{candidateProduct.depositScanCodeCheckDigit}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Product line, packaging, & regulatory info  -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Product line & Packaging'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Package type'" [canEdit]="false">
              {{candidate.packageType.description}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Unit of measure'" [canEdit]="false">
              {{candidate.unitOfMeasure.description}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Dimensions'" [canEdit]="false">
              {{candidateUtilService.getProductDimensionsString(candidate)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Weight'" [canEdit]="false">
              {{candidateUtilService.getProductWeightString(candidate.productWeight)}}
            </pm-grid-cell>
          </pm-grid-column>

          <pm-grid-column>
            <pm-grid-cell [label]="'Unit Size'" [canEdit]="false">
              {{candidate.totalVolume}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Retail size'" [canEdit]="false">
              {{candidate.retailSize}}
            </pm-grid-cell>
          </pm-grid-column>

        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 1'" [canEdit]="false">
              {{candidateProduct.customerFriendlyDescription1}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Shelf tag line 2'" [canEdit]="false">
              {{candidateProduct.customerFriendlyDescription2}}
            </pm-grid-cell>
          </pm-grid-column>
          <pm-grid-column>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'eComm copy'" [canEdit]="false">
              {{candidateProduct.romanceCopy}}
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

      <!--   Unit Details   -->
      <pm-attribute-grid *ngIf="!isSellable" [title]="'Unit details'">
        <pm-grid-group>
          <pm-grid-column>
            <div *ngIf="!candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit UPC'" [canEdit]="false">
                {{candidateProduct.upc}}-{{candidateProduct.upcCheckDigit}}
              </pm-grid-cell>
            </div>
            <div *ngIf="candidateUtilService.isPlu(candidate)">
              <pm-grid-cell [label]="'Unit PLU'" [canEdit]="false">
                <div *ngIf="candidateUtilService.isCheckerProduct(candidate)">
                  {{candidateProduct.upc | upc}}-{{candidateProduct.upc | upcCheckDigit}}
                </div>
                <div *ngIf="candidateUtilService.isScaleProduct(candidate)">
                  {{upcService.pluToG14PreDigitTwo(candidateProduct.upc)}}-{{upcService.pluToG14PreDigitTwo(candidateProduct.upc) | upcCheckDigit}}
                </div>
              </pm-grid-cell>
            </div>
            <pm-grid-cell [label]="'Product description'">
              {{candidateProduct.description}}
              <pm-grid-cell-info>
                {{candidateUtilService.getReceipt(candidateProduct.description) }}
              </pm-grid-cell-info>
            </pm-grid-cell>
            <pm-grid-cell  *ngxPermissionsOnly="['ROLE_CATEGORY_SELECTION-EDIT']" [label]="'MAT categories'" [canEdit]="false">
              {{matUtilService.getFullHierarchyPath(candidateProduct)}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>

        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'Images'" [canEdit]="false">
              <div *ngIf="!!productImages?.length" class="row">
                <ng-template ngFor let-image [ngForOf]="productImages">
                  <div class="col-3">
                    <img class="gallery-product-image" src="{{image}}">
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!productImages?.length">
                {{candidateUtilService.getImageLinksOmissionReasons(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell *ngxPermissionsOnly="['ROLE_LABEL_INSIGHTS']" [label]="'Label images'">
              <div *ngIf="labelInsightService.hasLabelInsightImages(labelInsightImages)" class="row">
                <ng-template ngFor let-image [ngForOf]="labelInsightImages">
                  <div class="col-3">
                    <img *ngIf="!labelInsightService.isPdf(image)" class="gallery-product-image" src="{{image}}">
                    <div *ngIf="labelInsightService.isPdf(image)" class="container">
                      <img src="/assets/images/icon-pdf.svg" class="gallery-product-pdf" title="{{image.name}}">
                      <div><i class="pi pi-cloud-download"  (click)="fileService.downloadFile(image)"></i></div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="!labelInsightService.hasLabelInsightImages(labelInsightImages)">
                {{labelInsightService.getLabelInsightReason(candidateProduct)}}
              </div>
            </pm-grid-cell>
            <pm-grid-cell [label]="'Attachments'" [canEdit]="false">
              <ul *ngIf="candidate.attachments.length" class="attachment-list">
                <li class="attachment" *ngFor="let file of candidate.attachments">
                  <div>
                    <label class="file-name-doc" [pTooltip]="file.name">{{file.name}}</label>
                    <i class="pi pi-download" (click)="fileService.downloadFile(file)"></i>
                  </div>
                </li>
              </ul>
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Global Attributes   -->
      <div *ngIf="showMatAttributes">
        <pm-attribute-grid [title]="'Extended attributes: Product'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of productAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid [title]="'Extended attributes: UPC'">
          <div *ngIf="isLoadingMatGlobalData" style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of upcAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>

        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && isLoadingMatGlobalData" [title]="'Extended attributes: Case'">
          <div style="display: flex; align-items: center">
            <pm-progress-spinner [showSpinner]="isLoadingMatGlobalData" [strokeWidth]="'2'"
                                 [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
            <h5 class="ml-3">Fetching extended attribute data...</h5>
          </div>
        </pm-attribute-grid>
        <pm-attribute-grid *ngIf="candidate?.warehouseSwitch && !isLoadingMatGlobalData && !!warehouseItemAttributes?.length" [title]="'Extended attributes: Case'">
          <pm-grid-group>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate, attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>
            <pm-grid-column>
              <div *ngFor="let attribute of warehouseItemAttributes; let i = index">
                <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                  <div *ngIf="matUtilService.isGlobalAttributeValueAnArray(candidate, attribute.identifiers.fieldId)">
                    <div *ngFor="let value of matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)">
                      {{value.description}} <br>
                    </div>
                  </div>
                  <div *ngIf="!matUtilService.isGlobalAttributeValueAnArray(candidate,attribute.identifiers.fieldId)">
                    {{matUtilService.getSelectedGlobalAttributeValue(candidate,attribute.identifiers.fieldId)}}
                  </div>

                </pm-grid-cell>
              </div>
            </pm-grid-column>

          </pm-grid-group>
        </pm-attribute-grid>
      </div>
      <!--   Mat Hierarchy   -->
      <pm-attribute-grid *ngIf="showMatAttributes && isLoadingMatHierarchyData && matUtilService.getMatHierarchyList(candidate).length > 0" [title]="'Category attributes'">
        <div style="display: flex; align-items: center">
          <pm-progress-spinner [showSpinner]="isLoadingMatHierarchyData" [strokeWidth]="'2'"
                               [style]="{width: '50px', height: '50px', paddingTop: '2.5rem'}"></pm-progress-spinner>
          <h5 class="ml-3">Fetching extended attribute data...</h5>
        </div>
      </pm-attribute-grid>
      <div *ngIf="showMatAttributes && hierarchyNumberToAttributesMap.size > 0">
        <div *ngFor="let hierarchy of candidateProduct.matHierarchyList">
          <pm-attribute-grid *ngIf="hierarchyNumberToAttributesMap.has(hierarchy.matHierarchyId)" [title]="'Category attributes: ' + hierarchy.name">
            <pm-grid-group>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 0" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 1" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>
              <pm-grid-column>
                <div *ngFor="let attribute of hierarchyNumberToAttributesMap.get(hierarchy.matHierarchyId); let i = index">
                  <pm-grid-cell *ngIf="i % 3 === 2" [label]="attribute.descriptions.businessFriendlyDescription" [canEdit]="false">

                    <div *ngIf="matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      <div *ngFor="let value of matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                        {{value.description}} <br>
                      </div>
                    </div>
                    <div *ngIf="!matUtilService.isHierarchyAttributeValueAnArray(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)">
                      {{matUtilService.getSelectedHierarchyAttributeValue(candidate, hierarchy.matHierarchyId, attribute.identifiers.fieldId)}}
                    </div>

                  </pm-grid-cell>
                </div>
              </pm-grid-column>

            </pm-grid-group>
          </pm-attribute-grid>
        </div>
      </div>


      <!--  Remarks -->
      <pm-attribute-grid [title]="'Remarks'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 1'" [canEdit]="false">
              {{candidateProduct?.remark1}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
        <pm-grid-group>
          <pm-grid-column>
            <pm-grid-cell [label]="'OMI line 2'" [canEdit]="false">
              {{candidateProduct?.remark2}}
            </pm-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>


      <!--   Comments   -->
      <pm-attribute-grid [title]="'Comments'">
        <pm-grid-group>
          <pm-grid-column>
            <pm-comment-grid-cell [label]="'comments'" [canEdit]="isPageEditable"  [hasReviewerComment]="hasReviewerComment()" [errorMessage]="candidateError?.scaComment" (editClicked)="onEditComment()">
              <div *ngIf="!!candidate.supplierComment">
                <b>{{candidate.contactEmail}}</b> <br>
                {{candidate.supplierComment}}
              </div>
              <br>

              <div *ngIf="!!candidate.buyerCommentUser">
                <b>{{candidate.buyerCommentUser}}</b>
                <br>
              </div>
              <div *ngIf="!!candidate.buyerComment">
                {{candidate.buyerComment}}
              </div>

              <br>

              <div *ngIf="!!candidate.scaComment">
                <b>{{candidate.scaName}}</b> <br>
                {{candidate.scaComment}}
              </div>
            </pm-comment-grid-cell>
          </pm-grid-column>
        </pm-grid-group>
      </pm-attribute-grid>

    </div>
  </div>
  <pm-review-drawer-container>
    <ng-container *ngIf="candidate && !openCommentDrawer">
      <div class="container p-3">
        <div class="row pb-3">
          <div class="col-6">
            <button pButton type="button" label="Collapse" class="ui-button-link" (click)="collapse()"></button>
          </div>
          <div class="col-6 text-right">
            <button pButton type="button" label="Save Changes" class="ui-button-link" (click)="save()">
            </button>
          </div>
        </div>

        <h2>Warehouse Details</h2>
        <p>Configure one or more of the warehouses below for this product.</p>

        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="maxShipConfiguration"
                                           [(model)]="candidate.maxShip"
                                           [attributeError]="candidateError?.maxShip">
          </pm-attribute-display-text-input>
        </div>

        <hr/>
        <ng-template ngFor let-warehouse [ngForOf]="warehouseData">

          <div class="attribute-group">
            <div>
              <p-checkbox id="whsCheckbox"
                          [(ngModel)]="warehouse.checked"
                          (onChange)="selectedWarehouseChange($event, warehouse)"
                          binary="true">
              </p-checkbox>
            </div>

            <div>
              <div class="attribute-label">
                <label>
                  {{warehouse.name}}
                </label>
              </div>
              <p class="attribute-description">
                ID: {{warehouse.omiId}}  |  Facility #: {{warehouse.warehouseId}}
              </p>
              <p *ngIf="warehouse.checked && candidateProductError?.warehouseErrors[warehouse.warehouseId]?.bicep"
                 class="error-message bicep-error">
                {{candidateProductError.warehouseErrors[warehouse.warehouseId].bicep}}
              </p>
            </div>

          </div>


          <div *ngIf="warehouse.checked">
            <div class="attributes-stacked">
              <pm-attribute-display-radio [attribute]="warehouse.orderUnitConfig"
                                          [(model)]="warehouse.orderUnitId"
                                          (change)="orderUnitChange($event, warehouse)"
                                          [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderUnit">
              </pm-attribute-display-radio>
              <pm-attribute-display-typeahead [attribute]="orderRestrictionConfiguration"
                                              [(model)]="warehouse.orderRestriction"
                                              (selection)="orderRestrictionChange($event, warehouse)"
                                              [attributeError]="candidateProductError?.warehouseErrors[warehouse.warehouseId]?.orderRestriction">
              </pm-attribute-display-typeahead>
            </div>

          </div>
          <hr/>

        </ng-template>

        <div class="attribute-group">
          <div>
            <p-checkbox id="whsMissingCheckbox"
                        [(ngModel)]="candidate.missingWarehouses"
                        (onChange)="warehouseMissingCheckboxChange($event)"
                        binary="true">
            </p-checkbox>
          </div>

          <div>
            <div class="attribute-label">
              <label style="font-size: 18px !important;">
                Warehouse missing?
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="candidate.missingWarehouses">
          <p style="font-size: 14px;">Not seeing the warehouse you need? This could happen if the bicep or AP number is wrong, or if a warehouse wasn't tied to a particular bicep.</p>
          <p style="font-size: 14px;">Right now, only Procurement Support can fix this issue. Let them know which warehouse you want to select and if they need to change the bicep or AP number referenced by this candidate.</p>
          <div class="attributes-stacked">
            <pm-attribute-display-text-input [attribute]="missingWarehousesCommentsConfiguration"
                                             [attributeError]="candidateError?.missingWarehousesComment"
                                             [(model)]="candidate.missingWarehousesComment"
                                             (keypress) ="updatedMissingWarehousesComment()">
            </pm-attribute-display-text-input>
          </div>
        </div>

        <hr/>

        <div class="text-right">
          <p-button class="m-2" label="Approve" (onClick)="onClickApprove()"
                    [disabled]="this.isApproveDisabled ||
                                (!this.isSelectedWarehouse() && !warehouseMissingNotesEntered)"
          ></p-button>
        </div>

      </div>
    </ng-container>
  </pm-review-drawer-container>


  <pm-review-drawer-container>
    <ng-container *ngIf="openCommentDrawer">
      <div class="container p-3">
        <div class="attributes-stacked">
          <pm-attribute-display-text-input [attribute]="scaComments"
                                           [(model)]="tempCommentHolder"
                                           [attributeError]="candidateError?.scaComment">
          </pm-attribute-display-text-input>
        </div>
        <div class="row pb-3">
          <div class="col-4">
            <p-button type="button" label="Cancel" class="ghost m-2" (click)="collapseCommentDrawer()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="!candidate.scaComment">
            <p-button type="button" label="Add Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
          <div class="col-8 text-right" *ngIf="candidate.scaComment">
            <p-button type="button" label="Update Comment" class="m-2" (click)="saveComment()"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </pm-review-drawer-container>

  <pm-footer>
    <p-button *ngIf="isPageEditable" class="m-2" label="Next" (onClick)="onClickNext()"></p-button>
  </pm-footer>
</pm-review>
